import React, { useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import { observer } from 'mobx-react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import PageTabs from '@/layout/PageTabs';
import { Icon } from '@/components';
import { useTabNavigate } from '@/hooks/useTabNavigate';

import type { MenuDataItem } from '@/const/route.config';
import type { MenuProps } from 'antd';

import './index.less';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (options: {
  label: React.ReactNode;
  key: string;
  icon?: string;
  children?: MenuItem[];
  type?: 'group' | 'divider';
}): MenuItem => {
  const { label, key, icon, children, type } = options;
  return {
    key,
    children,
    label,
    type,
    icon: icon ? <Icon type={icon} /> : undefined,
  };
};

interface SubMenuProps {
  menuData: MenuDataItem[];
  flatMenus: Record<string, MenuDataItem> | null;
  currentMenu?: MenuDataItem;
}

const SiderMenu: React.FC<SubMenuProps> = ({ menuData, flatMenus, currentMenu }) => {
  const navigator = useTabNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const { layout_keys: matchMenuKeys, pureMenu = true } = currentMenu || {};

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    if (matchMenuKeys?.length) {
      setOpenKeys(Array.from(new Set([...openKeys, ...matchMenuKeys])));
    }
  }, [matchMenuKeys]);

  const [key] = matchMenuKeys || [];

  let subMenuData: MenuDataItem[] = [];

  let firstMenuItem: MenuDataItem | undefined;

  if (key) {
    firstMenuItem = menuData?.find((item) => item.path === key);
    subMenuData = firstMenuItem?.children?.filter((item) => !item.hideInMenu) || [];
  }

  const subMenuItems = subMenuData.reduce<MenuItem[]>((res, item) => {
    if (item.hideInMenu) {
      return res;
    }

    const { path = '', icon, name, type, children } = item;
    const childreniItems = children?.reduce<MenuItem[]>((res, route) => {
      if (!route.hideInMenu) {
        const { path = '', icon, name } = route;
        res.push(getItem({ label: name, key: path, icon }));
      }

      return res;
    }, []);

    res.push(
      getItem({
        label: name,
        key: path,
        icon,
        children: childreniItems?.length ? childreniItems : undefined,
        type,
      }),
    );

    return res;
  }, []);

  const siderWidth = useMemo(() => (collapsed ? 58 : 180), [collapsed]);

  return (
    <div
      className="fle-layout-sider"
      style={{ minWidth: currentMenu && !pureMenu ? siderWidth : 0 }}
    >
      {currentMenu && !pureMenu && (
        <div className="fle-menu">
          <div className="fle-menu-space" style={{ width: siderWidth }} />
          <div className="fle-menu-wrap">
            <div
              className="menu-action-wrap"
              onClick={() => setCollapsed(!collapsed)}
              style={{ left: collapsed ? 45 : 168 }}
            >
              {collapsed ? (
                <RightOutlined className="menu-action-close-icon" />
              ) : (
                <LeftOutlined className="menu-action-open-icon" />
              )}
            </div>

            <div className="fle-menu-sider fle-scroll" style={{ width: siderWidth }}>
              <Menu
                mode="inline"
                theme="light"
                inlineIndent={20}
                items={subMenuItems}
                inlineCollapsed={collapsed}
                selectedKeys={matchMenuKeys}
                openKeys={collapsed ? undefined : openKeys}
                onOpenChange={(keys) => setOpenKeys(keys)}
                onClick={({ key }) => {
                  const currentMenu = flatMenus?.[key];
                  const url = currentMenu?.redirect || currentMenu?.path || key;
                  if (currentMenu?.target === '_blank') {
                    window.open(url);
                  } else {
                    navigator(url);
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}

      <PageTabs collapsed={collapsed} currentMenu={currentMenu} />
    </div>
  );
};
export default observer(SiderMenu);
