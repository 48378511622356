import axios from 'axios';
import type { AxiosResponse, AxiosError, Method, InternalAxiosRequestConfig } from 'axios';
import { notification } from '@/components/Message';
import { BaseURL, DataCode, TokenKey } from '@/const/serviceEnv';
import Cookies from 'js-cookie';
import { ApplicationInfo } from '@/const/application';

interface CommonObject {
  [key: string]: any;
}
interface PendingType {
  url?: string;
  method?: Method | string;
  params: any;
  data: any;
  cancel: Function;
}

const service = axios.create({
  baseURL: BaseURL,
  timeout: 30000,
});

const pending: PendingType[] = [];

const removePending = (config: InternalAxiosRequestConfig) => {
  for (const key in pending) {
    if (Object.prototype.hasOwnProperty.call(pending, key)) {
      const item: number = +key;
      const list: PendingType = pending[key];
      if (
        list.url === config.url &&
        list.method === config.method &&
        JSON.stringify(list.params) === JSON.stringify(config.params) &&
        JSON.stringify(list.data) === JSON.stringify(config.data)
      ) {
        list.cancel('操作太频繁，请稍后再试');
        pending.splice(item, 1);
      }
    }
  }
};

service.interceptors.request.use(
  async (config) => {
    removePending(config);

    const __config = config;

    const token = config.headers.authority_token || Cookies.get(TokenKey);

    if (token) {
      if (config.headers.Authorization !== undefined) {
        __config.headers.Authorization = __config?.headers.Authorization || token;
      } else {
        __config.headers.authority_token = token;
        __config.headers.system_id = ApplicationInfo.systemId;
      }
    }

    __config.url = `${config.url}?__date=${new Date().getTime()}`;

    // Create a new CancelToken for this request
    const source = axios.CancelToken.source();
    __config.cancelToken = source.token;

    pending.push({
      url: config.url,
      method: config.method,
      params: config.params,
      data: config.data,
      cancel: source.cancel,
    });

    if (config.url?.includes('/welcome/logout')) {
      if (config.data.baseURL) {
        __config.baseURL = config.data.baseURL;
        __config.headers.Authorization = __config.headers.authority_token;
        delete __config.headers.authority_token;
      }
    }
    if (config.params?.baseURL) {
      __config.baseURL = config.params.baseURL;
      if (__config.headers.authority_token) {
        __config.headers.Authorization = Cookies.get('liguanjia_member_token');
        __config.headers['Fx-Token'] = __config.headers.authority_token;
      }
      delete __config.headers.authority_token;
    }

    return __config;
  },
  (err: AxiosError) => {
    return Promise.reject(new Error(err.message || '网络错误'));
  },
);

let hasErrorOccurred = false; // 控制是否出现过错误的标志

service.interceptors.response.use(
  (res: AxiosResponse) => {
    const { data } = res;

    if (!data.success) {
      if (+data.status === 1) {
        return data;
      }

      if (
        +data.code === DataCode.get('LOGIN_ERROR') ||
        +data.code === DataCode.get('INVALID_LOGIN')
      ) {
        [
          'token',
          'liguanjia_member_token',
          'liguanjia_supplier_token',
          'liguanjia_card_token',
          'liguanjia_product_token',
        ].forEach(async (item: string) => {
          await Cookies.remove(item);
        });

        if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
          window.location.href = `/account/login?redirectUrl=${encodeURIComponent(
            window.location.origin + window.location.pathname,
          )}`;
        }

        throw new Error('登录失效');
      } else {
        if (!hasErrorOccurred) {
          hasErrorOccurred = true; // 当第一个接口报错时设置 hasErrorOccurred 为 true
          notification.error({
            message: '提示',
            description: data.message || data.msg,
            onClose: () => {
              hasErrorOccurred = false; // 当关闭提示时重置 hasErrorOccurred 为 false，允许之后的错误再次触发提示
            },
          });
        }
        throw new Error(data.message || data.msg || '网络错误');
      }
    } else {
      return data;
    }
  },
  (err: AxiosError) => {
    console.log(err);
    if (!hasErrorOccurred) {
      notification.error({
        message: '提示',
        description: err.message,
        onClose: () => {
          hasErrorOccurred = false; // 当关闭提示时重置 hasErrorOccurred 为 false，允许之后的错误再次触发提示
        },
      });
      hasErrorOccurred = true; // 当第一个请求出错时设置 hasErrorOccurred 为 true
    }
    return Promise.reject(new Error(err.message || '网络错误'));
  },
);

function get<T = any>(url?: string, config?: CommonObject): Promise<T> {
  return url
    ? service.get(url, config)
    : Promise.reject(new Error('Cannot read property URL of undefined'));
}

function post<T = any>(url?: string, data?: any, config?: CommonObject): Promise<T> {
  return url
    ? service.post(url, data, config)
    : Promise.reject(new Error('Cannot read property URL of undefined'));
}

const HttpClient = {
  get,
  post,
};

export default HttpClient;
