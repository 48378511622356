import React, { useState, useEffect, useLayoutEffect } from 'react';
import { CustomerServiceOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';
import NP from 'number-precision';
import { Tooltip, Dropdown, Badge, Watermark } from 'antd';
import { useFavicon, useTitle } from 'ahooks';
import { Message, Icon } from '@/components';
import { ProLayout } from '@ant-design/pro-components';
import { useLocation, Outlet } from 'react-router-dom';
import defaultSettings from '@/const/defaultSettings';
import { bus } from 'wujie';
import { AccountStore, CommonStore, PageCacheStore } from '@/stores';
import HeaderMenu from './HeaderMenu';
import SiderMenu from './SiderMenu';
import TabMenu from './TabMenu';
import AuthContainer from './AuthContainer';
import HeaderLeftContent from './HeaderLeftContent';
import TabLink from '@/components/TabLink';
// import AvatarPopover from './AvatarPopover';
import NoticeDrawer from './NoticeDrawer';
import { getFlatMenus, formartMenu } from '@/tools/getFlatMenus';
import { ApplicationInfo } from '@/const/application';
import getMatchMenu from '@/tools/getMatchMenu';
import { pptxUploadQueue } from '@/pages/microApp';
import { TokenKey, getApplicationUrl } from '@/const/serviceEnv';
import type { UserInfo } from '@/stores/account';
import type { MenuDataItem } from '@/const/route.config';
import { PLATFORM_TYPES } from '@/const/csc';

import './index.less';

export interface AuthorizedModuleType {
  authorizedCount: number;
  moduleId: string | number;
  moduleName: string;
  status: 0 | 1;
  totalGrantNum: number;
}

const loginUrl = '/account/login';
const HasToken = Cookies.get('token');
// 纯净模式展示的路由
const pureRoute = new Map([['/login', true]]);

const { systemId, title } = ApplicationInfo;

const BasicLayout: React.FC = () => {
  const location = useLocation();
  const { csInfo } = CommonStore;
  const hasCscRights =
    csInfo.csRight && ((csInfo.hasSeat && csInfo.seatStatus === 1) || csInfo.isAdmin);

  const [menu, setMenu] = useState<MenuDataItem[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [authModule, setAuthModule] = useState<AuthorizedModuleType[]>(); // 权限模块

  const [faviconUrl, setFaviconUrl] = useState<string>('');
  const [browserTitle, setBrowserTitle] = useState<string>('');

  useFavicon(faviconUrl);
  useTitle(browserTitle);

  // 扁平化菜单，用于搜索，避免频繁计算
  const [flatMenus, setFlatMenus] = useState<Record<string, MenuDataItem> | null>(null);

  const handleHasToken = async () => {
    await AccountStore.getLiguanjiaToken();
    AccountStore.getMenuPermission({
      systemId,
      resourceTypeList: [1, 2],
    }).then(async (res) => {
      const __menu = formartMenu(res, [title]);
      setMenu(__menu);

      const __flatMenus = __menu.length ? getFlatMenus(__menu) : null;
      setFlatMenus(__flatMenus);
    });
    AccountStore.getUserInfo().then((data) => {
      // CommonStore.getAuthModule({ employeeId: data?.id }).then((data) => setAuthModule(data));
      setUserInfo(data);
      window.localStorage.setItem('userInfo', JSON.stringify(data));
      setFaviconUrl(data.logoImgUrl || ApplicationInfo.textLogo);
      setBrowserTitle(data.portalName || ApplicationInfo.title);
    });
    // 获取站点id
    AccountStore.getSiteId();
  };
  useLayoutEffect(() => {
    if (HasToken) {
      handleHasToken();
    } else {
      const loginUrl = `/account/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
      window.location.hostname !== 'localhost' && window.location.replace(loginUrl);
    }
  }, []);

  const [matchMenu, setMatchMenu] = useState<MenuDataItem[]>([]);
  const [currentMenu, setCurrentMenu] = useState<MenuDataItem>();
  useEffect(() => {
    // 切换页面时，重置滚动条
    window.scrollTo(0, 0);

    if (flatMenus && location.pathname) {
      const pathname =
        location.pathname === '/redirectPage' ? location.state?.path : location.pathname;

      const _matchMenu = getMatchMenu(pathname, flatMenus, true, true);

      const checkMenu = _matchMenu?.[_matchMenu.length - 1];

      CommonStore.updateState({
        currentMenu: checkMenu,
      });
      setMatchMenu(_matchMenu);
      setCurrentMenu(checkMenu);

      if (checkMenu) {
        const { singleTab } = JSON.parse(decodeURIComponent(checkMenu.props || '{}'));

        const path = `${location.pathname}${location.search}`;
        const key = `${location.pathname}${singleTab ? '' : location.search}`;
        const name = checkMenu.name || '标签页';

        const cacheTab = {
          key,
          path,
          name,
          singleTab,
        };
        PageCacheStore.updatePageTab(cacheTab);

        bus.$emit('onShow', checkMenu, cacheTab);
      }
    }
  }, [flatMenus, location]);

  // 判断用户是否改变
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        AccountStore.checkUserId().then((data: any) => {
          if (!data) {
            window.location.replace('/module/workbench/dashboard');
          }
        });
      } else {
        // 当前标签页变为不可见状态
        // 执行相应的操作
      }
    });
  }, []);

  const logout = () => {
    Promise.allSettled([AccountStore.logout?.(), AccountStore.logoutother?.()]).then(
      async (res) => {
        if (res.filter((item) => item.status === 'fulfilled').length > 0) {
          await Cookies.remove('token');
          pptxUploadQueue.cleanAuth();
          window.location.href = loginUrl;
        }
      },
    );
  };

  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageNum, setMessageNum] = useState<number | null>(null);
  const [awaitApproveCount, setAwaitApproveCount] = useState<number>(0);
  const getApproveCountNum = async () => {
    const data: any = await CommonStore.tabStatistical({});
    setAwaitApproveCount(data.awaitApproveCount);
  };
  const getMessageCountNum = async () => {
    const param = {
      channelType: 10,
      isRead: -10, // 未读数量
    };
    const data: any = await CommonStore.remindCountNum(param);
    setMessageNum(NP.plus(data?.systemNum, data?.businessNum));
  };

  useEffect(() => {
    getApproveCountNum();
    getMessageCountNum();
    CommonStore.getCsPermissionInfo();
  }, []);

  useEffect(() => {
    const DEAFULT_WATERMARK_STYLE = Object.entries({
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      left: '0',
      top: '0',
      'pointer-events': 'none',
      'z-index': '1000',
    })
      .map(([key, value]) => `${key}:${value}`)
      .join(';');

    const watermark = document.querySelector('.watermark') as HTMLDivElement;

    watermark.setAttribute('style', DEAFULT_WATERMARK_STYLE);
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type !== 'attributes' && mutation.target !== watermark) {
          return;
        }
        const style = (mutation.target as HTMLDivElement).getAttribute('style');
        const className = (mutation.target as HTMLDivElement).getAttribute('class');

        if (mutation.attributeName === 'style' && style !== DEAFULT_WATERMARK_STYLE) {
          watermark.setAttribute('style', DEAFULT_WATERMARK_STYLE);
        } else if (mutation.attributeName === 'class' && className !== 'watermark') {
          watermark.className = 'watermark';
        }
      });
    });
    observer.observe(watermark, {
      attributes: true,
      childList: false,
      subtree: false,
    });

    const peliten__layout = document.querySelector('.peliten__layout') as HTMLDivElement;
    const observer2 = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.removedNodes.forEach((node) => {
            if ((node as HTMLDivElement)?.getAttribute('class') === 'watermark') {
              window.location.reload();
            }
          });
        }
      });
    });
    observer2.observe(peliten__layout, {
      attributes: false,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <>
      <ProLayout
        {...defaultSettings}
        siderWidth={180}
        location={location}
        className="peliten__layout"
        logo={ApplicationInfo.textLogo}
        title={ApplicationInfo.title}
        style={{ flexDirection: 'row', height: '100vh' }}
        pure={pureRoute.get(location.pathname)} // 是否展示纯净模式
        headerRender={(_, defaultDom) => <div id="fx__layout__header">{defaultDom}</div>}
        headerTitleRender={() => <HeaderLeftContent userInfo={userInfo} />}
        headerContentRender={() => (
          <HeaderMenu
            menuData={menu}
            flatMenus={flatMenus}
            matchMenuKeys={currentMenu?.layout_keys}
          />
        )}
        actionsRender={() => {
          return [
            !hasCscRights ? null : (
              <Tooltip title="平台客服">
                <a
                  className="fle-header-action-item"
                  onClick={() => {
                    const cscUrl = getApplicationUrl('csc');
                    const params: any = {
                      platform_type: PLATFORM_TYPES.carpdance_cs,
                      token: Cookies.get(TokenKey) || '',
                      redirectUrl: encodeURIComponent(window.location.origin),
                    };
                    const paramsStr = Object.keys(params)
                      .map((key) => `${key}=${params[key]}`)
                      .join('&');
                    window.open(`${cscUrl}/?${paramsStr}`, 'CscWindow');
                  }}
                >
                  <Badge size="small" count={csInfo.unreadMessages}>
                    <CustomerServiceOutlined size={16} />
                  </Badge>
                </a>
              </Tooltip>
            ),
            <Tooltip title="导入导出">
              <TabLink to="/module/common/importAndExport" className="fle-header-action-item">
                <Icon type="icon-icon_20pt_xiazaizhongxin_weixuanzhong" />
              </TabLink>
            </Tooltip>,
            <Tooltip title="审批中心">
              <TabLink to="/module/common/vetting" className="fle-header-action-item">
                <Badge
                  size="small"
                  offset={[8, -5]}
                  count={awaitApproveCount}
                  style={{ top: 5, right: 0 }}
                >
                  <Icon type="icon-icon_16pt_shenpizhongxin_weixuanzhong" />
                </Badge>
              </TabLink>
            </Tooltip>,
            <Tooltip title="消息中心">
              <div
                className="fle-header-action-item fle-header-action-item-div"
                onClick={() => {
                  setMessageOpen(true);
                }}
              >
                <Badge size="small" count={messageNum}>
                  <Icon
                    type="icon-icon_20pt_tongzhi_weixuanzhong"
                    className="fle-header-action-item-icon"
                  />
                </Badge>
              </div>
            </Tooltip>,
          ];
        }}
        avatarProps={{
          src: '//oss.elebuys.com/tmpdir/202308171743390002128752.png',
          title: userInfo?.name,
          style: { background: '#bbcfff' },
          render: (_, dom) => (
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    label: '退出登录',
                    onClick: logout,
                  },
                ],
              }}
            >
              <div style={{ whiteSpace: 'nowrap' }}>
                {dom}
                <DownOutlined style={{ fontSize: 12, marginLeft: 10 }} />
              </div>
            </Dropdown>
          ),
        }}
        menuRender={() =>
          HasToken && <SiderMenu menuData={menu} flatMenus={flatMenus} currentMenu={currentMenu} />
        }
        footerRender={false}
      >
        <AuthContainer authModule={authModule} matchMenu={matchMenu}>
          <div className="fx__layout-content">
            <TabMenu matchMenu={matchMenu} currentMenu={currentMenu} />
            <Outlet
              context={{
                userInfo,
                matchMenu,
                currentMenu,
                flatMenus,
              }}
            />
          </div>
        </AuthContainer>
        <React.Fragment>
          <Watermark
            className="watermark"
            content={userInfo ? ['', `${userInfo.name}`, `${userInfo.departmentName}`, ''] : ''}
            gap={[100, 100]}
            offset={[60, 80]}
            rotate={-15}
            font={{
              fontSize: 14,
              color: 'rgba(0, 0, 0, 0.05)',
              fontWeight: 400,
            }}
          />
        </React.Fragment>
      </ProLayout>

      <Message />
      <NoticeDrawer
        open={messageOpen}
        setMessageOpen={setMessageOpen}
        setMessageNum={setMessageNum}
      />
    </>
  );
};

export default observer(BasicLayout);
