import React, { useState, useEffect, useContext } from 'react';
import WujieReact from 'wujie-react';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';
import ProProvider from '@fle-ui/plus-context';
import theme from '@fle-ui/plus-theme';
import { useLocation, useOutletContext } from 'react-router-dom';
import { toJS } from 'mobx';
import { Loading, ErrorPage } from '@/components';
import { PageCacheStore, AccountStore } from '@/stores';
import { ApplicationInfo } from '@/const/application';
import { ApplicationUrlMap } from '@/const/serviceEnv';
import { useTabNavigate } from '@/hooks/useTabNavigate';
import { useWujieProps } from '@/hooks/useWujie';
import UploadQueue from '@/tools/upload';

import type { MenuDataItem } from '@/const/route.config';

import './index.less';

const { systemId, dashboard } = ApplicationInfo;

interface WujieReactItem {
  path: string;
  pathname: string;
  singleTab?: boolean;
  WujieReactDom: React.ReactElement;
}

interface OutletProps {
  currentMenu: MenuDataItem;
  matchMenu: MenuDataItem[];
  flatMenus: Record<string, MenuDataItem> | null;
}

interface ProProviderProps {
  wujieReactList: WujieReactItem[];
  setWujieReactList: React.Dispatch<React.SetStateAction<WujieReactItem[]>>;
}

const eventHandlers = new WeakMap();

const wujiePlugins = [
  {
    jsBeforeLoaders: [
      {
        content: `
        window.axios = window.parent.axios;
        window.React = window.parent.React;
        window.ReactDOM = window.parent.ReactDOM;
        window.classnames = window.parent.classnames;
        window.Selection = window.parent.Selection;
        window.global = global || globalThis || window;
      `,
      },
    ],
    cssBeforeLoaders: [
      {
        content: `
        body{background:transparent !important}
        #root{display:flex;flex-direction:column;}
        .fx-router-loading{min-height:calc(100vh - 124px) !important;}
      `,
      },
    ],
    windowAddEventListenerHook(
      iframeWindow: typeof Window,
      type: string,
      handler: Function,
      options: Object,
    ) {
      if (['mousedown'].includes(type)) {
        const _handler = function (e: Event) {
          const [target] = e.composedPath();
          Object.defineProperty(e, 'target', {
            value: target,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        };
        eventHandlers.set(handler, _handler);
        window.addEventListener(type, _handler, options);
      }
    },
    windowRemoveEventListenerHook(
      iframeWindow: typeof Window,
      type: string,
      handler: Function,
      options: Object,
    ) {
      if (['mousedown'].includes(type)) {
        const _handler = eventHandlers.get(handler);
        eventHandlers.delete(handler);
        window.removeEventListener(type, _handler, options);
      }
    },
  },
];

export const pptxUploadQueue = new UploadQueue(5);

const MicroApp: React.FC = () => {
  const [uninitialized, setUninitialized] = useState(true);
  const navigate = useTabNavigate();
  const location = useLocation();

  const { currentMenu, flatMenus } = useOutletContext<OutletProps>();
  const { wujieReactList, setWujieReactList } = useContext<ProProviderProps>(ProProvider as any);

  const { tabsPages } = PageCacheStore;

  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

  const { singleTab, wujieMode } = JSON.parse(decodeURIComponent(currentMenu?.props || '{}'));
  const currentPath = `${location.pathname}${singleTab ? '' : location.search}`;

  const cleanup = () => {
    const qiConnect = (window as any).qiConnect || (window.parent as any).qiConnect;
    if (qiConnect) {
      console.log('销毁七鱼工具条');

      // 退出系统
      qiConnect.logoffAsync?.().then(() => {
        // 销毁SDK实例
        qiConnect.clean?.();
      });
    }
  };
  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [location]);

  // 透传给子应用的props
  const wjProps = useWujieProps(
    {
      systemId,
      theme: theme.defaultTheme,
      wujieMode,
      systemIds: [systemId],
      getUserInfo() {
        return toJS(AccountStore.userInfo);
      },
      ApplicationUrlMap,
      // 跳转页面
      navigate: (urlOrDelta: string | number, options?: { target?: string; replace?: string }) => {
        if (typeof urlOrDelta === 'string') {
          if (options?.target === '_blank') {
            window.open(urlOrDelta, options.target);
            return;
          }

          navigate(urlOrDelta);
          return;
        }

        if (urlOrDelta === 0) {
          PageCacheStore.refreshPage(`${location.pathname}${location.search}`).then((path) => {
            path && navigate('/redirectPage', { state: { path }, replace: true });
          });
          return;
        }

        navigate(urlOrDelta);
      },
      // 重定向到某个页面并刷新（不传即为当前页）
      refreshPage: (path?: string) => {
        PageCacheStore.refreshPage(path || `${location.pathname}${location.search}`).then(
          (path) => {
            path && navigate('/redirectPage', { state: { path }, replace: true });
          },
        );
        // PageCacheStore.refreshPage(path || `${location.pathname}${location.search}`).then(
        //   (path) => {
        //     path &&
        //       navigate('/redirectPage', {
        //         state: { path: `${path}${search || ''}` },
        //         replace: true,
        //       });
        //   },
        // );
      },

      // 关闭当前页面
      closeSinglePage: (path?: string) =>
        PageCacheStore.closePageTab(path || `${location.pathname}${location.search}`),

      // 关闭所有页面
      closeAllPage: () => PageCacheStore.closeAllTabs(),

      // 退出登录
      logoutAndGoToLogin: (url?: string) => {
        Promise.allSettled([AccountStore.logout(), AccountStore.logoutother()]).then((res) => {
          if (res.filter((item) => item.status === 'fulfilled').length > 0) {
            [
              'token',
              'liguanjia_member_token',
              'liguanjia_supplier_token',
              'liguanjia_card_token',
              'liguanjia_member_token',
            ].forEach(async (item: string) => {
              await Cookies.remove(item);
            });
            pptxUploadQueue.cleanAuth();
            const loginUrl = `/account/login?redirectUrl=${encodeURIComponent(
              window.location.href,
            )}`;
            window.location.replace(url || loginUrl);
          }
        });
      },
      getNewPlusTag() {
        AccountStore.getUserInfo().then((data) => {
          // setUserInfo(data); 这里需要更新一下全局的权益状态
          AccountStore.updateUserInfo((info: any) => ({
            ...info,
            personal: data?.customerType === '21',
            rightsTag: data?.plusFlag,
            companyName: data?.companyName,
            adminFlag: data?.adminFlag,
            plate: '100',
          }));
          AccountStore.getTeamInfo({ company_id: data?.companyId }).then((res) => {
            AccountStore.updateUserInfo((info: any) => ({
              ...info,
              is_open_supplier: res?.data.is_open_supplier,
            }));
          });
        });
      },
      // AI 方案 ppt 上传
      pptxUploadQueue,
    },
    [currentMenu],
  );

  useEffect(() => {
    if (!currentMenu) return;
    setWujieReactList((wujieReactList) => {
      const loadUrl = `${location.pathname}${location.search}`.replace(/^\/module/, '');

      const { singleTab, wujieMode } = JSON.parse(decodeURIComponent(currentMenu?.props || '{}'));

      const hasWujieDom = wujieReactList.find(({ path, singleTab, pathname }) =>
        singleTab
          ? location.pathname === pathname
          : `${location.pathname}${location.search}` === path,
      )?.WujieReactDom;

      if (hasWujieDom) return wujieReactList;

      const WujieReactDom = (
        <WujieReact
          fiber
          alive={wujieMode ? wujieMode === 'alive' : true}
          width="100%"
          height="100%"
          url={loadUrl}
          key={loadUrl}
          name={currentPath}
          props={{ ...wjProps }}
          plugins={wujiePlugins}
          loadError={() => {
            setErrors((errors) => ({ ...errors, [currentPath]: true }));
          }}
          replace={(codeText) => codeText.replace(/const global = globalThis;\s*/g, '')}
        />
      );
      let newWujieReactList: WujieReactItem[] = [...wujieReactList];

      const foundindex = newWujieReactList.findIndex((item) =>
        singleTab ? item.pathname === location.pathname : item.path === currentPath,
      );
      const newWujieReact = {
        singleTab,
        WujieReactDom,
        path: currentPath,
        pathname: window.location.pathname,
      };

      if (singleTab) {
        if (foundindex > -1) {
          newWujieReactList.splice(foundindex, 1, newWujieReact);
        } else {
          newWujieReactList.push(newWujieReact);
        }
      } else if (foundindex === -1) {
        newWujieReactList.push(newWujieReact);
      }

      const isDashboard = currentPath.indexOf(dashboard) === 0;

      if (!isDashboard && wujieMode === 'alive') {
        newWujieReactList = newWujieReactList.filter(
          ({ path, pathname }) =>
            tabsPages.find((tab) => tab.key === (tab.singleTab ? pathname : path)) || isDashboard,
        );
      }

      return newWujieReactList;
    });
  }, [tabsPages, currentMenu]);

  useEffect(() => {
    flatMenus && setUninitialized(false);
  }, [flatMenus]);

  // 未初始化时
  if (uninitialized) return <Loading spinning />;

  if (!currentMenu) return <ErrorPage status="404" />;

  const currentWujieReact = wujieReactList.find(({ path, singleTab, pathname }) =>
    singleTab ? location.pathname === pathname : `${location.pathname}${location.search}` === path,
  )?.WujieReactDom;

  return (
    <div className="wujie-container">
      {errors[currentPath] ? <ErrorPage status="error" /> : currentWujieReact}
    </div>
  );
};
export default observer(MicroApp);
