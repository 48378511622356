import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import './index.less';
// antd 字体图标
const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/c/font_4285415_wwlq68yhu7o.js', // 智鲤主应用
    '//at.alicdn.com/t/c/font_4446488_qaseljua1vk.js', // 智理主架构、标签其它icon
    '//at.alicdn.com/t/c/font_4502274_je0ihul3eu.js', // 空置缺省页面
    '//at.alicdn.com/t/c/font_4567946_2oat87k7zjt.js',
    '//at.alicdn.com/t/font_2815636_b1ajo59kjxw.js',
  ],
});

interface MainProps {
  [key: string]: any;
  type: string;
}
const Icon: React.FC<MainProps> = (props) => <IconFont {...props} />;
export default Icon;
