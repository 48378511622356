import React, { useEffect, useState } from 'react';
import { CommonStore } from '@/stores';
import { List, Modal, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import '../index.less';

interface MessageBoxProps {
  messageType: string;
  tabType: string;
  setMessageOpen: (open: boolean) => void;
}
const appName = new Map([
  [10, '配置中心'],
  [20, '商品云'],
  [30, '方案云'],
  [40, '客户中心'],
  [50, '营销云'],
  [60, '数据云'],
  [70, '供应链云'],
]);

const MessageBox = (prop: MessageBoxProps) => {
  const navigate = useNavigate();
  const { messageType, tabType, setMessageOpen } = prop;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);
  const [showNoData, setShowNoData] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(false);
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setPageNo((pageNo) => pageNo + 1);
    setLoading(true);
    getMesaggeDataList(false, pageNo + 1);
    setLoading(false);
  };
  const getMesaggeDataList = async (ifFirst: boolean, no?: number) => {
    setShowNoData(false);
    const param = {
      channelType: 10,
      isRead: tabType === '0' ? null : tabType,
      pageNo: no || pageNo,
      type: messageType,
      pageSize: 10,
    };
    const res: any = await CommonStore.remindGetListByPage(param);

    if (ifFirst) {
      setData([]);
      setPageNo(1);
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setData(res.list);
      }, 100);
    } else {
      setData([...data, ...res.list]);
    }
    if (res.total === 0) {
      setShowNoData(true);
    }
    setTotal(res.total);
  };
  const seeDetail = async (list: any[], fromProduct: number, remindId: string) => {
    const param = {
      remindIds: [remindId],
    };
    await CommonStore.remindRead(param);
    navigate(list[0]);
  };
  const readMessage = async (e: any, item: any) => {
    if (e.target.nodeName === 'A') {
      const param = {
        remindIds: [item.remindId],
      };
      await CommonStore.remindRead(param);
    }
  };
  // 正则匹配
  const reg = (value: any, routeList: any[]) => {
    let temp = value.content;
    routeList.forEach((value) => {
      temp = temp.replace(/\{#(.*?)#\}/g, `<a href="${value}">$1</a>`);
    });
    return temp;
  };
  useEffect(() => {
    getMesaggeDataList(true, 1);
  }, [messageType, tabType]);

  return (
    <>
      <div
        id="scrollableDiv"
        className="content-scroll"
        style={{
          height: 'calc(100vh - 180px)',
          overflow: 'auto',
        }}
      >
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={data.length < total}
          loader={null}
          endMessage={
            <>
              {data && data.length > 0 && (
                <p style={{ color: '#AAAAAA', fontSize: 16, textAlign: 'center', marginTop: 20 }}>
                  已经到底啦～
                </p>
              )}
            </>
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={data}
            locale={{
              emptyText: showNoData && (
                <div className="message-no-data">
                  <img
                    className="no-data-img"
                    src="https://oss.elebuys.com/tmpdir/202311081443060003739028.png"
                    alt=""
                  />
                  <p>还没有任何信息</p>
                </div>
              ),
            }}
            renderItem={(item) => (
              <div className="message-box-root">
                <div className="message-app-name">{appName.get(item.fromModule)}</div>
                <div className="message-title-box">
                  <div className="message-title">
                    {item.title}
                    {item.isRead === -10 && <div className="red-dom" />}
                  </div>
                  <div className="message-time">{item.createTime.split('T').join(' ')}</div>
                </div>
                <Typography.Text
                  className="message-content"
                  onClick={(e) => {
                    readMessage(e, item);
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: reg(item, item.routeList) }} />
                </Typography.Text>
                {item.content?.length > 65 && (
                  <span
                    style={{ color: '#2c6bff', cursor: 'pointer', marginLeft: 4 }}
                    onClick={() => {
                      Modal.info({
                        icon: false,
                        title: item.title,
                        content: item?.content,
                        okText: '确认',
                      });
                    }}
                  >
                    全部
                  </span>
                )}
                {item?.routeList?.length > 0 && (
                  <div
                    className="message-see-detail"
                    onClick={() => {
                      setMessageOpen(false);
                      seeDetail(item.routeList, item.fromProduct, item.remindId);
                    }}
                  >
                    查看详情
                  </div>
                )}
              </div>
            )}
          />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default MessageBox;
