import React, { useEffect, useMemo, useState } from 'react';
import Iframe from 'react-iframe';
import Cookies from 'js-cookie';
import { pathToRegexp, match, compile } from 'path-to-regexp';
import { TokenKey, getApplicationUrl } from '@/const/serviceEnv';
import { useLocation } from 'react-router-dom';
import { useTabNavigate } from '@/hooks/useTabNavigate';
import { Spin } from 'antd';
import './index.less'
interface WebviewProps {
  url: string;
  needLGJtoken?: boolean;
  isSupplier?: boolean;
  width?: string;
  height?: string;
  needCenter?: boolean;
  parseQueryToMatch?: boolean;
}

const LiguanjiaManager = getApplicationUrl('manager');
const LiguanjiaManagerFront = `${LiguanjiaManager}/front-end/#`;

interface WebviewType {
  name: string;
  path: string;
  props: WebviewProps;
}

const webviewList: WebviewType[] = [
  /** 3.0运营端front-end * */

  {
    name: '商城样板',
    path: '/webview/manager/mallCreate',
    props: {
      url: `${LiguanjiaManagerFront}/mallCreate/mall_list`,
    },
  },
  {
    name: '创建商城样板',
    path: '/webview/manager/mallCreate/add',
    props: {
      url: `${LiguanjiaManagerFront}/mallCreate/mall_edit`,
    },
  },
  {
    name: '编辑商城样板',
    path: '/webview/manager/mallCreate/edit',
    props: {
      url: `${LiguanjiaManagerFront}/mallCreate/mall_edit`,
    },
  },

  {
    name: '商城知识',
    path: '/webview/manager/knowledgeManage',
    props: {
      url: `${LiguanjiaManagerFront}/knowledgeManage/knowledge_list`,
    },
  },
  {
    name: '新增商城知识',
    path: '/webview/manager/knowledgeManage/add',
    props: {
      url: `${LiguanjiaManagerFront}/knowledgeManage/knowledge_edit`,
    },
  },
  {
    name: '编辑商城知识',
    path: '/webview/manager/knowledgeManage/edit',
    props: {
      url: `${LiguanjiaManagerFront}/knowledgeManage/knowledge_edit`,
    },
  },

  {
    name: '文章管理',
    path: '/webview/manager/articleManage',
    props: {
      url: `${LiguanjiaManagerFront}/articleManage/article_list`,
    },
  },
  {
    name: '新增文章',
    path: '/webview/manager/articleManage/add',
    props: {
      url: `${LiguanjiaManagerFront}/articleManage/article_edit`,
    },
  },
  {
    name: '编辑文章',
    path: '/webview/manager/articleManage/edit',
    props: {
      url: `${LiguanjiaManagerFront}/articleManage/article_edit`,
    },
  },

  {
    name: '案例管理',
    path: '/webview/manager/caseCcenter',
    props: {
      url: `${LiguanjiaManagerFront}/case_center/case_list`,
    },
  },
  {
    name: '新增案例',
    path: '/webview/manager/caseCcenter/add',
    props: {
      url: `${LiguanjiaManagerFront}/case_center/create_case`,
    },
  },
  {
    name: '编辑案例',
    path: '/webview/manager/caseCcenter/edit',
    props: {
      url: `${LiguanjiaManagerFront}/case_center/create_case`,
    },
  },

  {
    name: '标签管理',
    path: '/webview/manager/labelManage',
    props: {
      url: `${LiguanjiaManagerFront}/label_manage`,
    },
  },

  {
    name: '视频管理',
    path: '/webview/manager/videoManage',
    props: {
      url: `${LiguanjiaManagerFront}/videoManage/video_list`,
    },
  },
  {
    name: '新增视频',
    path: '/webview/manager/videoManage/add',
    props: {
      url: `${LiguanjiaManagerFront}/videoManage/video_edit`,
    },
  },
  {
    name: '编辑视频',
    path: '/webview/manager/videoManage/edit',
    props: {
      url: `${LiguanjiaManagerFront}/videoManage/video_edit`,
    },
  },

  {
    name: '营销插件管理',
    path: '/webview/manager/marketingPlugins',
    props: {
      url: `${LiguanjiaManagerFront}/marketing_center/marketing_list`,
    },
  },
  {
    name: '新增营销插件',
    path: '/webview/manager/marketingPlugins/add',
    props: {
      url: `${LiguanjiaManagerFront}/marketing_center/create_marketing`,
    },
  },
  {
    name: '编辑营销插件',
    path: '/webview/manager/marketingPlugins/edit',
    props: {
      url: `${LiguanjiaManagerFront}/marketing_center/create_marketing`,
    },
  },

  {
    name: '解决方案管理',
    path: '/webview/manager/solution',
    props: {
      url: `${LiguanjiaManagerFront}/solution_center/solution_list`,
    },
  },
  {
    name: '新增解决方案',
    path: '/webview/manager/solution/add',
    props: {
      url: `${LiguanjiaManagerFront}/solution_center/create_solution`,
    },
  },
  {
    name: '编辑解决方案',
    path: '/webview/manager/solution/edit',
    props: {
      url: `${LiguanjiaManagerFront}/solution_center/create_solution`,
    },
  },

  {
    name: '技术应用管理',
    path: '/webview/manager/openPlatform',
    props: {
      url: `${LiguanjiaManagerFront}/open_platform/platform_list`,
    },
  },
  {
    name: '新增应用',
    path: '/webview/manager/openPlatform/add',
    props: {
      url: `${LiguanjiaManagerFront}/open_platform/create_platform`,
    },
  },
  {
    name: '编辑应用',
    path: '/webview/manager/openPlatform/edit',
    props: {
      url: `${LiguanjiaManagerFront}/open_platform/create_platform`,
    },
  },

  {
    name: '集市装修',
    path: '/webview/manager/selectionPlatform/operatePage',
    props: {
      url: `${LiguanjiaManagerFront}/selectionPlatform/operatePage`,
    },
  },

  {
    name: '微页面管理',
    path: '/webview/manager/tinyPage',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/tiny_page/list`,
    },
  },
  {
    name: '新增微页面',
    path: '/webview/manager/tinyPage/add',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/tiny_page/update`,
    },
  },
  {
    name: '编辑微页面',
    path: '/webview/manager/tinyPage/edit',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/tiny_page/update`,
    },
  },
  {
    name: '微页面添加记录',
    path: '/webview/manager/tinyPage/addLog',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/tiny_page/add_log`,
    },
  },
  {
    name: '上架商城清单',
    path: '/webview/manager/tinyPage/linkLog',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/tiny_page/link_log`,
    },
  },

  {
    name: '专题管理',
    path: '/webview/manager/plfTopic',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/list`,
    },
  },
  {
    name: '新增专题',
    path: '/webview/manager/plfTopic/add',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/update`,
    },
  },
  {
    name: '编辑专题',
    path: '/webview/manager/plfTopic/edit',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/update`,
    },
  },
  {
    name: '专题添加记录',
    path: '/webview/manager/plfTopic/addLog',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/special/add_log`,
    },
  },
  {
    name: '上架商城清单',
    path: '/webview/manager/plfTopic/linkLog',
    props: {
      url: `${LiguanjiaManagerFront}/plf_topic/special/link_log`,
    },
  },

  {
    name: '选品装修标签',
    path: '/webview/manager/goods/labelsManager',
    props: {
      url: `${LiguanjiaManagerFront}/goods/labelsManager`,
    },
  },

  {
    name: '商城代运营',
    path: '/webview/mall/operate',
    props: {
      url: `${LiguanjiaManagerFront}/operate_manage/operate`,
    },
  },

  {
    name: '礼包中心',
    path: '/webview/manager/template/store/gift',
    props: {
      url: `${LiguanjiaManagerFront}/gift_package/create_package`,
    },
  },

  /** 3.0运营端 （php） * */

  {
    name: '小程序/商户号配置',
    path: '/webview/wechat/merchant/config',
    props: {
      url: `${LiguanjiaManager}/Third_wechat_merchant/index`,
    },
  },
  {
    name: '添加微信商户号',
    path: '/webview/wechat/merchant/add',
    props: {
      url: `${LiguanjiaManager}/third_wechat_merchant/wechat_create`,
    },
  },
  {
    name: '添加小程序',
    path: '/webview/wechat/weapp/add',
    props: {
      url: `${LiguanjiaManager}/third_wechat_merchant/mini_create`,
    },
  },
  {
    name: '商户号日志信息',
    path: '/webview/wechat/merchant/config/log',
    props: {
      url: `${LiguanjiaManager}/log/index`,
    },
  },
  {
    name: '编辑小程序/商户号',
    path: '/webview/wechat/merchant/edit/:id',
    props: {
      url: `${LiguanjiaManager}/third_wechat_merchant/edit/:id`,
    },
  },
  {
    name: '小程序/商户号一键复制',
    path: '/webview/wechat/merchant/fork/:id',
    props: {
      url: `${LiguanjiaManager}/third_wechat_merchant/fork/:id`,
    },
  },
  {
    name: '短信签名审核',
    path: '/webview/sms/signature/review',
    props: {
      url: `${LiguanjiaManager}/message_sn/lst`,
    },
  },
  {
    name: '短信签名详情',
    path: '/webview/message/detail',
    props: {
      url: `${LiguanjiaManager}/message_sn/detail`,
    },
  },
  {
    name: '模版管理',
    path: '/webview/manager/template/store',
    props: {
      url: `${LiguanjiaManager}/template/store_style_view`,
    },
  },
  {
    name: '模版标签',
    path: '/webview/manager/template/storeLabel',
    props: {
      url: `${LiguanjiaManager}/template/store_style_label_view`,
    },
  },
  {
    name: '选品标签',
    path: '/webview/manager/managerLabel',
    props: {
      url: `${LiguanjiaManager}/manager_label/manager_label_view`,
    },
  },
  {
    name: '商城资产',
    path: '/webview/manager/storeIndex',
    props: {
      url: `${LiguanjiaManager}/store/index`,
    },
  },
  {
    name: '卡券资产',
    path: '/webview/manager/cardTemplets',
    props: {
      url: `${LiguanjiaManager}/card_templets_user/index`,
    },
  },
  {
    name: '图片库装修管理',
    path: '/webview/manager/picture',
    props: {
      url: `${LiguanjiaManager}/picture/listInfo`,
    },
  },

  {
    name: '商品订单',
    path: '/webview/manager/goodsOrder',
    props: {
      url: `${LiguanjiaManager}/order/esOrderList`,
    },
  },
  {
    name: '订单详情',
    path: '/webview/manager/goodsOrder/detail/:id',
    props: {
      url: `${LiguanjiaManager}/order/orderDetail/:id`,
    },
  },
  {
    name: '历史订单',
    path: '/webview/manager/order/history',
    props: {
      url: `${LiguanjiaManager}/old/order/orderList`,
    },
  },

  {
    name: '售后订单',
    path: '/webview/manager/afterSaleOrder',
    props: {
      url: `${LiguanjiaManager}/order/after_sale_index`,
    },
  },
  {
    name: '订单详情',
    path: '/webview/manager/afterSaleOrder/detail/:id',
    props: {
      url: `${LiguanjiaManager}/sale/view/:id`,
    },
  },

  {
    name: '线下退款订单',
    path: '/webview/manager/offlineRefundManager',
    props: {
      url: `${LiguanjiaManager}/offlineRefundManager/index`,
    },
  },

  {
    name: '京东异常售后',
    path: '/webview/manager/saleRefundJdLog',
    props: {
      url: `${LiguanjiaManager}/sale/refundJdLog`,
    },
  },
  {
    name: '京东无返回',
    path: '/webview/manager/saleRefundJd',
    props: {
      url: `${LiguanjiaManager}/sale/refundJd`,
    },
  },
  {
    name: '京东异常售后详情',
    path: '/webview/manager/saleRefundJdLog/detail/:id',
    props: {
      url: `${LiguanjiaManager}/sale/view/:id`,
    },
  },

  {
    name: '京东待提交订单',
    path: '/webview/manager/jdErrOrder',
    props: {
      url: `${LiguanjiaManager}/order/jd_error`,
    },
  },

  {
    name: '催单订单',
    path: '/webview/manager/reminderOrder',
    props: {
      url: `${LiguanjiaManager}/order/reminder_list`,
    },
  },

  {
    name: '订单号查询会员',
    path: '/webview/manager/queryTeamByOrder',
    props: {
      url: `${LiguanjiaManager}/feature/queryTeam`,
    },
  },

  {
    name: '工单管理',
    path: '/webview/manager/serviceOrder',
    props: {
      url: `${LiguanjiaManager}/service_order/index`,
    },
  },

  {
    name: 'E生活退款订单',
    path: '/webview/manager/icbcRefunds',
    props: {
      url: `${LiguanjiaManager}/sale/icbcRefunds`,
    },
  },

  {
    name: '订单物流关键字',
    path: '/webview/manager/orderKeyword',
    props: {
      url: `${LiguanjiaManager}/order/express_keyword`,
    },
  },
  {
    name: '新增关键字',
    path: '/webview/manager/orderKeyword/add',
    props: {
      url: `${LiguanjiaManager}/order/express_keyword_add`,
    },
  },
  {
    name: '编辑关键字',
    path: '/webview/manager/orderKeyword/edit',
    props: {
      url: `${LiguanjiaManager}/order/express_keyword_edit`,
    },
  },

  {
    name: '挑选供应商商品',
    path: '/webview/manager/productPick',
    props: {
      url: `${LiguanjiaManager}/product/pick_list`,
    },
  },

  {
    name: '已挑选商品',
    path: '/webview/manager/productSelected',
    props: {
      url: `${LiguanjiaManager}/product/select_list`,
    },
  },

  {
    name: '信息变动审核',
    path: '/webview/manager/productChange',
    props: {
      url: `${LiguanjiaManager}/product_change/lists`,
    },
  },
  {
    name: '信息变动审核详情',
    path: '/webview/manager/productChange/detail/:id',
    props: {
      url: `${LiguanjiaManager}/product_change/info/:id`,
    },
  },

  {
    name: '得力分类匹配',
    path: '/webview/manager/deliCategoryMatch',
    props: {
      url: `${LiguanjiaManager}/v2/deli/Category/matchList`,
    },
  },

  {
    name: '平台分类管理',
    path: '/webview/manager/productCategory',
    props: {
      url: `${LiguanjiaManager}/product_category/index`,
    },
  },
  {
    name: '添加平台分类',
    path: '/webview/manager/productCategory/add/:pid',
    props: {
      url: `${LiguanjiaManager}/product_category/add/:pid`,
    },
  },
  {
    name: '编辑平台分类',
    path: '/webview/manager/productCategory/edit/:categoryId',
    props: {
      url: `${LiguanjiaManager}/product_category/edit/:categoryId`,
    },
  },
  {
    name: '查看下级分类',
    path: '/webview/manager/productCategory/sub/:categoryId',
    props: {
      url: `${LiguanjiaManager}/product_category/index/:categoryId`,
    },
  },

  {
    name: '供应商账号',
    path: '/webview/manager/supplier',
    props: {
      url: `${LiguanjiaManager}/supplier/supplier_lists`,
    },
  },
  {
    name: '新增供应商',
    path: '/webview/manager/supplier/add',
    props: {
      url: `${LiguanjiaManager}/supplier/add_supplier`,
    },
  },
  {
    name: '编辑供应商',
    path: '/webview/manager/supplier/edit/:supplierId',
    props: {
      url: `${LiguanjiaManager}/supplier/edit_supplier/:supplierId`,
    },
  },
  {
    name: '供应商详情',
    path: '/webview/manager/supplier/detail/:supplierId',
    props: {
      url: `${LiguanjiaManager}/supplier/supplier_detail/:supplierId`,
    },
  },

  {
    name: '账号关联合同',
    path: '/webview/manager/supplierContract',
    props: {
      url: `${LiguanjiaManager}/supplier_contract/contract_lists`,
    },
  },
  {
    name: '新增关联合同',
    path: '/webview/manager/supplierContract/add',
    props: {
      url: `${LiguanjiaManager}/supplier_contract/add_suppliercontract`,
    },
  },
  {
    name: '编辑关联合同',
    path: '/webview/manager/supplierContract/edit/:supplierId',
    props: {
      url: `${LiguanjiaManager}/supplier_contract/edit_suppliercontract/:supplierId`,
    },
  },
  {
    name: '关联合同详情',
    path: '/webview/manager/supplierContract/detail/:supplierId',
    props: {
      url: `${LiguanjiaManager}/supplier_contract/suppliercontract_detail/:supplierId`,
    },
  },

  {
    name: '交易管理',
    path: '/webview/manager/financeManager',
    props: {
      url: `${LiguanjiaManager}/finance/manager_deal`,
    },
  },
  {
    name: '交易管理详情',
    path: '/webview/manager/financeManager/orderDetail/:id',
    props: {
      url: `${LiguanjiaManager}/order/orderDetail/:id`,
    },
  },
  {
    name: '3.0服务账户交易',
    path: '/webview/manager/financeService',
    props: {
      url: `${LiguanjiaManager}/finance/manager_deal_B`,
    },
  },
  {
    name: '站点交易明细',
    path: '/webview/manager/financeRecord',
    props: {
      url: `${LiguanjiaManager}/finance/manager_deal_C`,
    },
  },
  {
    name: '线下退款审核',
    path: '/webview/manager/offlineRefund/index',
    props: {
      url: `${LiguanjiaManager}/offlineRefund/index`,
    },
  },

  {
    name: '万鲤商联开票审核',
    path: '/webview/manager/invoiceUnion',
    props: {
      url: `${LiguanjiaManager}/invoice/indexService`,
    },
  },
  {
    name: '万鲤商联开票审核详情',
    path: '/webview/manager/invoiceUnion/detail',
    props: {
      url: `${LiguanjiaManager}/invoice/detail`,
    },
  },
  {
    name: '象加开票审核',
    path: '/webview/manager/invoiceUnion/invoiceXj',
    props: {
      url: `${LiguanjiaManager}/invoice/xjIndex`,
    },
  },
  {
    name: '飞象企服开票审核',
    path: '/webview/manager/invoiceElephant',
    props: {
      url: `${LiguanjiaManager}/invoice/index`,
    },
  },
  {
    name: '飞象企服开票审核详情',
    path: '/webview/manager/invoiceElephant/detail',
    props: {
      url: `${LiguanjiaManager}/invoice/p_detail`,
    },
  },
  {
    name: '飞象企服商品与服务发票',
    path: '/webview/manager/invoiceElephant/Recoder',
    props: {
      url: `${LiguanjiaManager}/invoice/recoder`,
    },
  },
  {
    name: '待开票汇总',
    path: '/webview/manager/finance/invoiceQuery',
    props: {
      url: `${LiguanjiaManager}/finance/invoiceQuery`,
    },
  },
  {
    name: '技术服务发票',
    path: '/webview/manager/financeTechnical',
    props: {
      url: `${LiguanjiaManager}/finance/manager_deal_S`,
    },
  },
];

const Webview: React.FC = () => {
  const [targetUrl, setTargetUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useTabNavigate();

  const { url, width, height, needCenter } = useMemo(() => {
    try {
      const _found = webviewList.find((item) => pathToRegexp(item.path).test(location.pathname));
      // 防止引用变量props.url影响路由拼接值
      const found = _found ? JSON.parse(JSON.stringify(_found)) : _found;
  
      if (!found) {
        navigate('/404', { replace: true });
      } else {
        const matcher = match(found.path, { decode: decodeURIComponent });
        const matchRes = matcher(location.pathname as string);
  
        if (matchRes && matchRes.params) {
          const urlArr = found.props.url.split('://');
          const toPath = compile(urlArr[1], { encode: encodeURIComponent });
          const path = toPath(matchRes.params);
          found.props.url = `${urlArr[0]}://${path}`;
        }
  
        found.props.url = `${found.props.url}${location.search}`;
      }
      return found?.props || { url: '' };
    } catch (error) {
      return { url: '' };
    }

  }, [location]);

  const handleLoad = () => {
    setLoading(false);
  };
  const appendTokenToURL = (url: string) => {
    const token = Cookies.get(TokenKey);
    const queryParams = [`micro=1`, `pangu_token=${token}`];

    const queryString = queryParams.join('&');
    const newUrl = url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`;
    setTargetUrl(newUrl);
  };

  useEffect(() => {
    const handlePostMessage = (event: any) => {
      if (event?.data?.type === 'link' && event?.data?.data) {
        navigate(event?.data?.data);
      }
    };

    if (url) {
      window.addEventListener('message', handlePostMessage, false);
      appendTokenToURL(url);
    }
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [url]);

  return (
    <> 
    {loading &&  <div className="fx-webview-loading"><Spin size="large" /></div>}
    <div
      style={
        needCenter
          ? { display: 'flex', justifyContent: 'center', height: '100%' }
          : { height: '100%' }
      }
    >
      <Iframe key={targetUrl} url={targetUrl} width={width || '100%'} height={height || '100%'} frameBorder={0}  onLoad={handleLoad}/>
    </div>
    </>
  );
};

export default Webview;
