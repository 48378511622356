import { makeAutoObservable, runInAction } from 'mobx';
import request from '@/tools/httpClient';
import { common } from '@/api';
import type { MenuDataItem } from '@/const/route.config';

import type { StateProps } from '@/types/common/store';
import { PLATFORM_TYPES, POSITIONS, RELATION_TYPES } from '@/const/csc';
import { message } from '@/components/Message';

class CommonStore {
  [key: string]: any;

  singleHref = '';
  currentMenu: MenuDataItem | null = null; // 当前菜单
  csInfo: {
    csRight?: boolean;
    isAdmin?: boolean;
    hasSeat?: boolean;
    unreadMessages?: number;
    seatStatus?: number;
  } = {};
  csShowInfo: {
    show?: boolean;
    csNo?: string;
  } = {};

  constructor() {
    makeAutoObservable(this);
  }

  // 获取消息列表
  remindGetListByPage = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindGetListByPage, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 统计消息数量
  remindCountNum = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindCountNum, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 消息设为已读
  remindRead = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindRead, params).then(({ data, success }) => {
          if (success) {
            resolve(true);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取验证码（通用）
  getSmsCode = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.smsCode, params).then(({ data, success }) => {
          if (success) {
            resolve(true);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取角标
  tabStatistical = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        const headers = { system_id: '100002' };
        request.post(common.tabStatistical, params, { headers }).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取行业列表
  getCompanyIndustryList = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.industryList)
          .then(({ data, success }) => {
            if (success && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取猜你喜欢
  getGuessList = async (params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.getGuessList, { params })
          .then(({ data, success }) => {
            if (success && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取客服权限信息
  getCsPermissionInfo = async (): Promise<any> => {
    request
      .post(common.csPermission, {}, { headers: { platform_type: PLATFORM_TYPES.carpdance_cs } })
      .then(({ data, success }) => {
        if (success && data) {
          this.updateState({
            csInfo: data || {},
          });
        } else {
          this.updateState({ csInfo: {} });
        }
      })
      .catch(() => {
        this.updateState({ csInfo: {} });
      });
  };
  // 获取咨询客服权限信息
  getCsShowInfo = async (relationId: any): Promise<any> => {
    request
      .post(
        common.csShow,
        {
          relationId,
          relationType: RELATION_TYPES.carpdancePlatform,
          position: POSITIONS.carpdancePlatform,
        },
        { headers: { platform_type: PLATFORM_TYPES.carpdance_user } },
      )
      .then(({ data, success }) => {
        if (success && data) {
          this.updateState({
            csShowInfo: data || {},
          });
        } else {
          this.updateState({ csShowInfo: {} });
        }
      })
      .catch(() => {
        this.updateState({ csShowInfo: {} });
      });
  };

  /**
   * 员工授权模块明细
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAuthModule = async (params: any) => {
    const res = await request.get(common.getAuthModule, { params });
    return res.data;
  };

  /** 查询当前用户有权限看到的员工列表 */
  getManagedEmployeeList = async (params: {
    /** 员工ID列表 */
    employeeIdList?: string[];
    /** 员工手机，精准查询 */
    mobile?: string;
    /** 员工姓名，模糊查询 */
    name?: string;
    /** 页码，默认1 */
    pageNo?: number;
    /** 每页条数, 默认10 */
    pageSize?: number;
  }) => {
    const res = await request.post('/elebuys/member/employee/managed/employee-list', params);
    return res.data;
  };

  /** 根据客户负责人、协作人查询对应客户所属会员下拉列表 */
  getCompanyListByEmployeeIds = async (params: {
    /**
     * 客户协作人ID列表
     */
    associatesIdList?: string[];
    /**
     * 企业ID，精确查询
     */
    companyId?: string;
    /**
     * 企业名称，模糊查询
     */
    companyName?: string;
    /**
     * 页码，默认1
     */
    pageNo?: number;
    /**
     * 每页条数, 默认10
     */
    pageSize?: number;
    /**
     * 客户负责人ID列表
     */
    principalIdList?: string[];
    /**
     * 客户负责人或协作人ID列表
     */
    principalOrAssociatesIdList?: string[];
  }) => {
    const res = await request.post('/admin/customer/select-list/company', params);
    return res.data;
  };

  /** 根据客户负责人、协作人查询对应客户下拉列表 */
  getCustomerListByEmployeeIds = async (params: {
    /**
     * 客户协作人ID列表
     */
    associatesIdList?: string[];
    /**
     * 客户ID，精确查询
     */
    customerId?: string;
    /**
     * 客户名称，模糊查询
     */
    customerName?: string;
    /**
     * 页码，默认1
     */
    pageNo?: number;
    /**
     * 每页条数, 默认10
     */
    pageSize?: number;
    /**
     * 客户负责人ID列表
     */
    principalIdList?: string[];
    /**
     * 客户负责人或协作人ID列表
     */
    principalOrAssociatesIdList?: string[];
  }) => {
    const res = await request.post('/admin/customer/select-list/customer', params);
    return res.data;
  };

  // 看板url
  completedUrl = async (params: {
    panelNo: string;
    [key: string]: any;
  }): Promise<{
    panelUrl: string;
    expireTime: number;
  }> => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.completedUrl, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (err: any) {
        message.error(err.message);
        reject();
      }
    });
  };

  updateState = (params: StateProps) => {
    for (const [key, value] of Object.entries(params)) {
      runInAction(() => {
        this[key] = value;
      });
    }
  };
}

export default new CommonStore();
