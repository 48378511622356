import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import PlusConfigProvider from '@fle-ui/plus-config-provider';
import Router from '@/router';

import '@/theme/reset.less';
import '@/style/index.less';

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container!);

root.render(
  <PlusConfigProvider>
    <Router />
  </PlusConfigProvider>,
);
